import { NavLink } from 'react-router-dom';
import Logo from '../images/logo.png'
import { useContext, useState } from 'react';
import { ThemeContext } from '../Context/Theme/Context';
import { Button, SideDrawer } from '../Library/Module';
import SearchContainer from '../container/SearchContainer';
import searchIcon from "../images/search.png"
const Header = () => {
  const { mainCategory } = useContext(ThemeContext)
  const [searchBox, setSearchBox] = useState(false)
  const CloseDrawer = () => {
    setSearchBox(false)
  };

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <NavLink to={'/'}><img src={Logo} title="" alt='' /></NavLink>
            </div>
            <div className="col-md-9 col-sm-12 text-right">
              <div className="top_nav">
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="http://customers.dynamicfabrics.net/">customer login</a>
                  </li>
                  <li>
                    <NavLink className='alternative_btn' to={'/make-a-payment'}>Make a Payment</NavLink>
                  </li>

                  <li>
                    <Button className='' onClick={() => setSearchBox(true)}>
                      <img className='searchIcon' src={searchIcon} title='' alt='' />
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="main_nav">
                <ul>
                  {mainCategory?.map((menu: any) => {
                    return <>
                      <li>
                        <NavLink to={`category/${menu?.slug}`}>{menu?.title === "Drappery" ? 'Drapery' : menu?.title}</NavLink>
                      </li>
                    </>
                  })}
                  <li>
                    <NavLink to={'/about-us'}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/find-dealer'}>Find A Dealer</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/contact-us'}>Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchBox &&
        <SideDrawer
          size={'650px'}
          pagetitle={`Search Fabric`}
          action={CloseDrawer}>
          <SearchContainer CloseDrawer={CloseDrawer}></SearchContainer>
        </SideDrawer>
      }
    </>
  );
};
export default Header;
