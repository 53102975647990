import { useEffect, useState } from "react";
import { Button, Input } from "../Library/Module";
import { EnquiryDetails } from "../servies/services";
import { toast } from "react-toastify";

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};

const ContactForm = () => {
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading]: any = useState(false);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = async () => {
        const APIResponse = await EnquiryDetails(formData);
        if (APIResponse?.data?.isSuccess === true) {
            toast.success('Your enquiry has been submitted successfully.', {
                // @ts-ignore: Unreachable code error
                position: toast.POSITION.TOP_CENTER
            });

            setFormData({
                full_name: '',
                business_name: '',
                email_id: '',
                phone_number: '',
                commnet: ""
            });
        } else {
            toast.error("Something went wrong, please try again");
            setIsLoading(true);
        }
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])
    return (
        <>
            <div className="contact-form">
                <div className="row align-items-center">
                    <Input
                        inputName="full_name"
                        inputType="text"
                        labelName={"Full Name"}
                        placeholder="Enter Full Name"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.full_name}
                    />

                    <Input
                        inputName="business_name"
                        inputType="text"
                        labelName={"Business Name"}
                        placeholder="Enter Business Name"
                        required={false}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.business_name}
                    />

                    <Input
                        inputName="email_id"
                        inputType="email"
                        labelName={"Email ID"}
                        placeholder="Enter Email ID"
                        required={true}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.email_id}
                    />

                    <Input
                        inputName="phone_number"
                        inputType="text"
                        labelName={"Phone Number"}
                        placeholder="Enter Phone Number"
                        required={false}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.phone_number}
                    />

                    <Input
                        inputName="commnet"
                        inputType="textarea"
                        labelName={"Comment"}
                        placeholder="Enter Comment"
                        required={false}
                        onChangeSingleCallback={onChangeSingleCallback}
                        col="12"
                        isFormSubmitted={isFormSubmitted}
                        value={formData?.commnet}
                    />
                    <div className="mb-3 col-md-12">
                        <Button
                            loading={false}
                            onClick={() => Submit()}
                            buttonText={"Submit"}
                            buttonStyleType={"primary"}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm