import { useCallback, useContext, useMemo } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import Collection from '../images/new_collection.png'
import Collection2 from '../images/new_collection2.png'
import Collection3 from '../images/new_collection3.png'
import catDrappery from '../images/drappery_home.png'
import catDrappery2 from '../images/upholstery_home.png'
import { NavLink } from "react-router-dom";
import SimpleSlider from "../components/Slider";
import Contracts2 from '../images/Contracts_home2.png'
import Contracts from '../images/Contracts_home.png'

interface HomeProps {
    link: string;
}

const HomePage = ({ link }: HomeProps): JSX.Element => {
    const { catlogData } = useContext(ThemeContext)


    const drapperyData = useMemo(() => {
        if (catlogData?.length !== 0) {
            return catlogData?.filter((item: any) => {
                return item?.category === 'drappery'
            })
        }
    }, [catlogData])

    const upholsteryData = useMemo(() => {
        if (catlogData?.length !== 0) {
            return catlogData?.filter((item: any) => {
                return item?.category === 'upholstery'
            })
        }
    }, [catlogData])

    const contractsData = useMemo(() => {
        if (catlogData?.length !== 0) {
            return catlogData?.filter((item: any) => {
                return item?.category === 'contracts'
            })
        }
    }, [catlogData])

    console.log("upholsteryData?.[0]?.image", upholsteryData?.[0]?.image?.[0])

    return (
        <>
            <SimpleSlider></SimpleSlider>
            <div className="container pt-4 pb-2">
                <div className="new_collection section">
                    <h1 className="text-center">BROWSE OUR NEW COLLECTIONS</h1>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="ttitle">{drapperyData?.[0]?.title}</div>
                            <img src={process.env.react_app_base_url + "/" + drapperyData?.[0]?.image?.[0]} alt="" title="" />
                            <NavLink className="btn btn-primary" to={`category/drappery/${drapperyData?.[0]?.slug}`}>View Collection</NavLink>
                        </div>
                        <div className="col-md-4 col-sm-12">
                        <div className="ttitle">{upholsteryData?.[0]?.title}</div>
                            <img src={process.env.react_app_base_url + "/" + upholsteryData?.[0]?.image?.[0]} alt="" title="" />
                            <NavLink className="btn btn-primary" to={`category/upholstery/${upholsteryData?.[0]?.slug}`}>View Collection</NavLink>
                        </div>

                        <div className="col-md-4 col-sm-12">
                        <div className="ttitle">{contractsData?.[0]?.title}</div>
                            <img src={process.env.react_app_base_url + "/" + contractsData?.[0]?.image?.[0]} alt="" title="" />
                            <NavLink className="btn btn-primary" to={`category/contracts/${contractsData?.[0]?.slug}`}>View Collection</NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-2 pb-2">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="section">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <h1>Drapery</h1>
                                    <p>Our linen curtains are a stylish and elegant addition to any home or office space. Made from high-quality linen fibers, these curtains are lightweight, breathable, and durable. Linen curtains come in a variety of colors and textures, making it easy to find the perfect match for any décor.</p>
                                    <p>Known for its softness and durability, making it a great choice for curtains that will be used frequently.Overall, linen curtains are a versatile and stylish choice for any home or office. Their natural material, light-blocking properties, and durability make them a practical choice, while their elegant appearance adds a touch of luxury to any space.</p>
                                    <NavLink className="btn btn-primary" to={'category/drappery'}>VIEW CATALOGUE</NavLink>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <img src={catDrappery} alt="" title="" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="section">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <h1>Upholstery</h1>
                                    <p>Our upholstery range includes a wide range of fabrics, designs, and textures
                                        that can be customized to meet the specific needs and preferences of our customers.
                                        We use only the finest materials that are durable, easy to clean, and eco-friendly.</p>
                                    <p>Our skilled craftsmen have years of experience in creating bespoke upholstery for
                                        different types of furniture, including chairs, sofas, cttcmans, and benches. We use
                                        state-of-the-art equipment and technigues to ensure that every piece of upholstery
                                        is crafted to perfection.</p>

                                    <NavLink className="btn btn-primary" to={'category/upholstery'}>VIEW CATALOGUE</NavLink>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <img src={catDrappery2} alt="" title="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-2 pb-2">
                <div className="section">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <h1 className="">Contracts</h1>
                            <p>D3 Contract fabrics meets flame-retardant and IMO standard while being luxurious and high performance at the same time. Commercial interiors such as offices, schools, universities, cinema, auditoria, bars, restaurants and healthcare must all adhere to additional standards and requirements that exceed the performance requirements of fabrics used for domestic/residential interiors. We provides designers, specifiers and architects extensive range of fabric selection with new and trendy designs and colors that will transform interiors while meeting fire-retardant standards and fitting a wide range of project budgets.</p>
                            <NavLink className="btn btn-primary" to={'category/contracts'}>VIEW CATALOGUE</NavLink>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <img src={Contracts} alt="" title="" />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <img src={Contracts2} alt="" title="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HomePage;
