import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import thumbnail from '../images/thumbnail.jpg'
import { Button, Input, SideDrawer, Slider } from "../Library/Module"
import { ThemeContext } from "../Context/Theme/Context"
import ContactForm from "../components/ContactForm"

type productGridType = {
    data: any,
    selectedProduct: (data: any) => void
    showDescription?: boolean
    hideCollection?: boolean
    showStockBtn?: boolean
    rowClass?: string
    isCategory?: boolean
}

const ProductGrid = ({
    data, selectedProduct,
    showDescription = false,
    hideCollection = false,
    showStockBtn = false,
    rowClass = '',
    isCategory = false

}: productGridType): JSX.Element => {
    const { mainCategory, breadcrumb, updateBreadcrumb, updateCatlogData, catlogData } = useContext(ThemeContext)

    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const [image, setImage] = useState<any>(undefined)

    useEffect(() => {
        setImage(data?.image?.[0] ?? thumbnail)
    }, [data?.image])


    const selectedImage = useCallback((data:any) => {
        setImage(data)
    }, [])

    const productDescription = ({
        hideDescription = false
    }) => {
        return (<div className="description">
            {hideDescription && <h3 className="pageTitle">Product Description </h3>}
            <div className="clearfix"></div>
            <div dangerouslySetInnerHTML={{ __html: data?.description }} />
        </div>
        )
    }
    const Inquiry = () => {
        setOpen(true)
    }

    const CloseDrawer = () => {
        setOpen(false)
    };




    return (<>
        <>
            <div className={isCategory ? 'col-md-6 col-sm-12 mb-4' : 'col-md-12 col-sm-12 mb-4'}>
                <div className="section">
                    <div className="row">
                        <div className={isCategory ? "col-md-6 col-sm-12" : "col-md-4 col-sm-12"}>
                            <Slider title={data?.title} category={data?.category} images={data?.image}></Slider>
                            <div className="clearfix"></div>
                            <Button onClick={() => Inquiry()} buttonStyleType="primary" buttonStyleOutline > <span className="material-symbols-outlined"> description</span> Enquiry Now </Button>
                        </div>
                        <div className={isCategory ? "col-md-6 col-sm-12" : "col-md-3 col-sm-12"}>
                            <h3 className="pageTitle">{data?.title} </h3>
                            <div className="clearfix"></div>
                            {data?.short_description ?
                                <div className="short_description">
                                    <div dangerouslySetInnerHTML={{ __html: data?.short_description }} />
                                </div>
                                : productDescription(
                                    { 'hideDescription': false }
                                )}
                            {hideCollection && <p>
                                <NavLink className="btn btn-outline-primary" to={`/category/${data?.category}/${data?.slug}`}><span className="material-symbols-outlined"> reorder </span> View Collection</NavLink>
                                {/* <Button buttonStyleType="primary" buttonStyleOutline onClick={() => navigate(`/category/${data?.category}/${data?.slug}`)}> <span className="material-symbols-outlined"> reorder </span> View Collection </Button> */}
                            </p>}
                            {showStockBtn && data?.stock_url && <p>
                                <a target="_blank" className="btn btn-outline-primary" href={data?.stock_url} rel="noreferrer"><span className="material-symbols-outlined"> inventory_2 </span> Stock Availability</a>
                                {/* <Button buttonStyleType="primary" buttonStyleOutline onClick={() => window.open(data?.stock_url, '_blank')}> <span className="material-symbols-outlined"> inventory_2 </span> Stock Availability </Button> */}
                            </p>}

                            {data?.download_collection?.[0] &&
                                <p><a target="_blank" href={process.env.react_app_base_url + "/" + data?.download_collection} rel="noreferrer" className="btn btn-outline-primary" ><span className="material-symbols-outlined"> download_for_offline </span> Download Catalog </a></p>
                            }
                        </div>
                        {showDescription &&
                            <div className="col-md-4 col-sm-6 mb-4">
                                {productDescription({ 'hideDescription': true })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
        {open &&
            <SideDrawer
                size={'450px'}
                pagetitle={`Enquiry for ${data?.title} - ${data?.category} `}
                action={CloseDrawer}>
                <ContactForm></ContactForm>
            </SideDrawer>
        }
    </>)
}

export default ProductGrid