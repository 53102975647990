import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";

import { Button, Input, Select } from "../Library/Module";
import { placeOrder } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

interface HomeProps {
    link: string;
}

const MakePayment = ({ link }: HomeProps): JSX.Element => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<any>();
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    const Submit = async () => {
        if (formData?.name?.length > 0 && formData?.email_id?.length > 0 && formData?.amount?.length > 0 && formData?.invoice_number?.length > 0) {
            setIsLoading(true)
            const postData = {
                "name": formData?.name,
                "address": formData?.address,
                "email_id": formData?.email_id,
                "phone_number": formData?.phone_number,
                "invoice_number": formData?.invoice_number,
                "amount": formData?.amount,
                "amount_paid": formData?.amount,
                "payment_method": formData?.payment_method ?? 'Card',
                "payment_status": formData?.payment_status ?? 'Failed',
                "payment_id": formData?.payment_id ?? '-',
                "order_id": formData?.order_id ?? '-',
            }

            // const APIResponse = await placeOrder(postData);
            // console.log("APIResponse", APIResponse?.data?.data)
            // if (APIResponse?.data?.data?.payment_status === "Failed") {
            //     toast.error("Your payment has been failed due to invalid details, please try again");
            //     navigate(`/payment-details/${APIResponse?.data?.data?.['_id']}`);
            // }

            console.log("postData ----------- ", postData)
            try {
                // 
                // test // pk_test_51PvekOLid9IsIxWN7mLDVmsXkg8fPiS6vGQj0qKSffpg6uDIRVhJA8lfpV9wD4V08zpyExJZhoQblHUhPldP2O1400EJMVBLb4
                // Live pk_live_51PvekOLid9IsIxWNHBkGeRZe2SAp18vaa0iifvpqSZHWhba5LxOoxz7nRxkZLSAVrbtndgCEl2gESYwGX5caAm8q00Y1YA1Psu
                // const stripe = await loadStripe("pk_test_51PvekOLid9IsIxWN7mLDVmsXkg8fPiS6vGQj0qKSffpg6uDIRVhJA8lfpV9wD4V08zpyExJZhoQblHUhPldP2O1400EJMVBLb4") as any
                const stripe = await loadStripe("pk_live_51PvekOLid9IsIxWNHBkGeRZe2SAp18vaa0iifvpqSZHWhba5LxOoxz7nRxkZLSAVrbtndgCEl2gESYwGX5caAm8q00Y1YA1Psu") as any
                const headers = {
                    "Content-Type": "application/json",
                };

                const response = await fetch(
                    "https://dynamicfabrics.net/nodeapi/api/v1/payment",
                    {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(postData),
                    }
                );

                const session = await response.json();
                console.log("session?.data?.session", session?.seesion)
                const result = stripe.redirectToCheckout({
                    sessionId: session?.seesion?.id,
                });

                if (result.error) {
                    console.log(result.error);
                }

            } catch (error: any) {
                alert('Failed' + error);
                console.log('placeorder API error', error.response);
                setIsLoading(false)
            }

        } else {
            toast.error("Please enter all required fields.");
        }
    }


    return (
        <>
            <div className="banner banner2">
                <div className="banner_text">
                    <h3 className="changeCase">Make a Payment</h3>
                </div>
            </div>

            <div className="container pt-4 pb-4">
                <h1 className="text-center">Make a Payment</h1>
                <p className="text-center">Any question or remarks? Just write us a message!</p>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-sm-12">
                        <div className="section">
                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="row">
                                            <Input
                                                inputName="name"
                                                inputType="text"
                                                labelName={"Name"}
                                                placeholder="Enter Name"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.name}
                                            />

                                            <Input
                                                inputName="address"
                                                inputType="text"
                                                labelName={"Address"}
                                                placeholder="Enter Address"
                                                required={false}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.address}
                                            />

                                            <Input
                                                inputName="email_id"
                                                inputType="email"
                                                labelName={"Email ID"}
                                                placeholder="Enter Email ID"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.email_id}
                                            />
                                        </div> </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="row">
                                            <Input
                                                inputName="phone_number"
                                                inputType="text"
                                                labelName={"Phone Number"}
                                                placeholder="Enter Phone Number"
                                                required={false}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.phone_number}
                                            />

                                            <Input
                                                inputName="invoice_number"
                                                inputType="text"
                                                labelName={"Invoice number"}
                                                placeholder="Enter Invoice number"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.commnet}
                                            />

                                            <Input
                                                inputName="amount"
                                                inputType="number"
                                                labelName={"Amount"}
                                                placeholder="Enter Amount"
                                                required={true}
                                                onChangeSingleCallback={onChangeSingleCallback}
                                                col="12"
                                                isFormSubmitted={isFormSubmitted}
                                                value={formData?.amount}
                                            />
                                            <div className="col-md-6 col-sm-12 text-right"></div>
                                            <div className="col-md-6 col-sm-12 text-right">
                                                <Button
                                                    onClick={() => Submit()}
                                                    buttonText={"Make a Payment"}
                                                    buttonStyleType={"primary"}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MakePayment;


// export const displayStripPay = async (postData: any) => {
//     console.log("postData ----------- ", postData)
//     try {
//         //
//         // test // pk_test_51O25mhSGukGk5MZ2KJYQjFzawUXN7BsOjETBD6bLLZX1IUi2pALxlF4nEqM8PZs4x40tSDMZEUPyvoLIFpcdAc1b00bmOoNYgk
//         const stripe: any = await loadStripe("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");
//         const headers = {
//             "Content-Type": "application/json",
//         };

//         const response = await fetch(
//             "https://dynamicfabrics.net/nodeapi/api/v1/payment",
//             {
//                 method: "POST",
//                 headers: headers,
//                 body: JSON.stringify(postData),
//             }
//         );

//         const session = await response.json();

//         const result = stripe.redirectToCheckout({
//             sessionId: session.id,
//         });

//         if (result.error) {
//             console.log(result.error);
//         }

//     } catch (error: any) {
//         alert('Failed');
//         console.log('placeorder API error', error.response.data.messages);
//         const message = error.response.data.messages;
//         toast(message)
//     }
// }