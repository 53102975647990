import { useEffect, useState } from "react";

import { Button, Input } from "../Library/Module";
import { getPlaceOrder, getRegions, placeOrder } from "../servies/services";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface HomeProps {
    link: string;
}

const ThankYou = ({ link }: HomeProps): JSX.Element => {
    const [searchParams] = useSearchParams();
    const session_id = searchParams.get('session_id')

    console.log("searchParams", searchParams, session_id)

    const [paymentData, setPaymentData] = useState<any>([])
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)

    useEffect(() => {
        setIsPaymentLoading(false)
        const getData = async () => {
            const APIResponse = await getPlaceOrder(session_id);
            console.log("APIResponse", APIResponse)

            setPaymentData(APIResponse?.data?.data)

        };
        getData();
        setIsPaymentLoading(true)
    }, [session_id])



    return (
        <>
            <div className="banner banner2">
                <div className="banner_text">
                    <h3 className="changeCase">Thank you for shopping with us.</h3>
                </div>
            </div>

            <div className="container pt-4 pb-4">
                <h1 className="text-center">Payment Details</h1>
                <p className="text-center">Any question or remarks? Just write us a message!</p>
                <div className="row justify-content-center">
                    <div className="col-md-8 col-sm-12">
                        {isPaymentLoading &&
                            <div className="section paymentInfo">
                                <div className={`note mb-3 ${paymentData?.payment_status}`}>Your payment has been {paymentData?.payment_status}</div>
                                <p><label>Name</label>{paymentData?.name}</p>
                                <p><label>Email Id</label>{paymentData?.email_id}</p>
                                <p><label>Phone Number</label>{paymentData?.phone_number}</p>
                                <p><label>Invoice Number</label>{paymentData?.invoice_number}</p>
                                <p><label>Amount</label>{paymentData?.amount}</p>
                                <p><label>Amount Paid</label>{paymentData?.amount_paid}</p>
                                <p><label>Payment Method</label>{paymentData?.payment_method}</p>
                                <p><label>Order Id</label>{paymentData?.order_id}</p>
                                <p><label>Payment Status</label>{paymentData?.payment_status}</p>
                                <p><label>Payment Id</label>{paymentData?.payment_id}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
export default ThankYou;
