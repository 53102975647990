import { NavLink } from 'react-router-dom';
import Logo from '../images/logo.png'
const PageNotFound = () => {
    return (
        <div className="banner banner2">
            <div className="banner_text">
                <h3 className="changeCase">Page Not Found</h3>
            </div>
        </div>
    );
};
export default PageNotFound;
