import React, { Component } from "react";
import Slider from "react-slick"
import Image1 from '../images/239-slide 1.jpg'
import Image2 from '../images/239-slide 2.jpg'
import Image3 from '../images/239-slide 3.jpg'
import Image4 from '../images/banner.png'

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000

        };
        return (
            <div className="sliderMain">
                {/* @ts-ignore: Unreachable code error */}
                <Slider {...settings}>
                    <div className="sliderImage" >
                        <div className="imageSlider" style={{ backgroundImage: `url("${Image4}")` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="banner">
                                            <div className="banner_text">
                                                <h3>"An empty home is a story waiting to happen... <br />
                                                    ... and you are the author” </h3>
                                                <h4>Charlotte Moss, Interior Designer</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sliderImage" >
                        <div className="imageSlider" style={{ backgroundImage: `url("${Image1}")` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="banner">
                                            <div className="banner_text">
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h3>"An empty home is a story waiting to happen... <br />
                                                        ... and you are the author” </h3>
                                                </Animated>
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h4>Charlotte Moss, Interior Designer</h4>
                                                </Animated>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sliderImage" >
                        <div className="imageSlider" style={{ backgroundImage: `url("${Image2}")` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="banner">
                                            <div className="banner_text">
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h3>"An empty home is a story waiting to happen... <br />
                                                        ... and you are the author” </h3>
                                                </Animated>
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h4>Charlotte Moss, Interior Designer</h4>
                                                </Animated>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sliderImage" >
                        <div className="imageSlider" style={{ backgroundImage: `url("${Image3}")` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="banner">
                                            <div className="banner_text">
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h3>"An empty home is a story waiting to happen... <br />
                                                        ... and you are the author” </h3>
                                                </Animated>
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h4>Charlotte Moss, Interior Designer</h4>
                                                </Animated>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}
