import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Button } from "../../Library/Module";
import { GetCategory, getCatlog } from "../../servies/services";
import { objectToQueryString } from "../../Library/Utility/Utility";
type defaultType = { id: string; name: string };
type ThemeContextType = {
    orgUser: defaultType;
    updateOrgUser: (data: defaultType) => void;
    updateBgColor: (data: string) => void;
    bgTextColor: string;
    breadcrumb: React.ReactNode;
    updateBreadcrumb: (data: any) => void;
    mainCategory: any;
    catlogData: any;
    updateCatlogData: (data: any) => void;

};
export const ThemeContext = createContext<ThemeContextType>({
    orgUser: { id: "", name: "" },
    updateOrgUser: (data: defaultType) => null,
    updateBgColor: (data: string) => null,
    bgTextColor: "",
    breadcrumb: "",
    updateBreadcrumb: (data: any) => null,
    mainCategory: [],
    catlogData: [],
    updateCatlogData: (data: any) => null,
});

const { Provider } = ThemeContext;

export const ThemeConsumer = ThemeContext.Consumer;

const defaultValue: defaultType = { id: "", name: "" };

type ThemeProviderProps = {
    children: JSX.Element;
};

const ThemeProvider = ({ children }: ThemeProviderProps): JSX.Element => {
    const navigate = useNavigate();
    const [orgUser, setOrgUser] = useState(defaultValue);
    const [bgTextColor, setBgTextColor] = useState("");
    const [breadcrumb, setBreadcrumb] = useState<any>("");
    const [catlogData, setCatlogData] = useState<any>([]);

    const updateCatlogData = useCallback((data: any) => {
        setCatlogData(data);
    }, []);

    const updateOrgUser = useCallback((data: any) => {
        setOrgUser(data);
    }, []);

    const updateBgColor = useCallback((data: any) => {
        setBgTextColor(data);
    }, []);

    const updateBreadcrumb = useCallback((data: any) => {
        const abc: any = () => {
            const back = data?.length > 2 ? data?.[data.length - 2] : data?.[0];
            return (
                <>
                    <div className="breadcrub">
                        <div className="container">
                            <ul>
                                {data?.map((item: any) => {
                                    return (
                                        <li>
                                            <NavLink to={item?.link}>{item?.name}</NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="float-right">
                                <Button
                                    onClick={() => navigate(back?.link)}
                                    buttonSize="sm"
                                    buttonStyleType="primary"
                                >
                                    Back
                                </Button>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </>
            );
        };
        setBreadcrumb(abc);
    }, []);

    const [mainCategory, setMainCategory] = useState([]);
    useEffect(() => {
        const getData = async () => {
            const APIResponse = await GetCategory();
            if (APIResponse?.data?.isSuccess === true) {
                let updatedList: any = []
                APIResponse?.data?.data?.map((item: any) => {
                    return updatedList.push({ text: item?.title, id: item?._id, value: item?.slug })
                })
                setMainCategory(APIResponse?.data?.data);
            } else {
                console.error("something went wrong, please try after sometime.")
            }
        };
        getData();
    }, [])

    useEffect(() => {
        const getData = async () => {
            const query = {
                status: 1
            }
            const APIResponse = await getCatlog(objectToQueryString(query));
            if (APIResponse?.data?.isSuccess === true) {
                if (APIResponse?.data?.data) {
                    updateCatlogData(APIResponse?.data?.data?.reverse())
                }
            } else {
                console.error("something went wrong, please try after sometime.")
            }

        };
        getData();
    }, [updateCatlogData])

    console.log("customPostType", setMainCategory)

    return (
        <>
            <Provider
                value={{
                    orgUser,
                    updateOrgUser,
                    bgTextColor,
                    updateBgColor,
                    breadcrumb,
                    updateBreadcrumb,
                    mainCategory,
                    catlogData,
                    updateCatlogData
                }}
            >
                {children}
            </Provider>
        </>
    );
};

export { ThemeProvider };
