import Chile1 from '../images/chile/chile1.jpg'
import Chile2 from '../images/chile/chile2.jpg'
import Chile3 from '../images/chile/chile3.jpg'
import Chile4 from '../images/chile/chile4.jpg'
import Chile5 from '../images/chile/chile5.jpg'
import Chile6 from '../images/chile/chile6.jpg'

// import chileCollection01 from '../images/chile/chile_collection_01.jpg'
// import chileCollection02 from '../images/chile/chile_collection_02.jpg'
// import chileCollection03 from '../images/chile/chile_collection_03.jpg'
// import chileCollection04 from '../images/chile/chile_collection_04.jpg'
// import chileCollection05 from '../images/chile/chile_collection_05.jpg'
// import chileCollection06 from '../images/chile/chile_collection_06.jpg'
// import chileCollection07 from '../images/chile/chile_collection_07.jpg'
// import chileCollection08 from '../images/chile/chile_collection_08.jpg'
// import chileCollection09 from '../images/chile/chile_collection_09.jpg'
// import chileCollection10 from '../images/chile/chile_collection_10.jpg'
// import chileCollection11 from '../images/chile/chile_collection_11.jpg'
// import chileCollection12 from '../images/chile/chile_collection_12.jpg'
// import chileCollection13 from '../images/chile/chile_collection_13.jpg'
// import chileCollection14 from '../images/chile/chile_collection_14.jpg'
// import chileCollection15 from '../images/chile/chile_collection_15.jpg'
// import chileCollection16 from '../images/chile/chile_collection_16.jpg'

import Capetown1 from '../images/capetown/capetown1.jpg'
import Capetown2 from '../images/capetown/capetown2.jpg'
import Capetown3 from '../images/capetown/capetown3.jpg'
import Capetown4 from '../images/capetown/capetown4.jpg'
import Capetown5 from '../images/capetown/capetown5.jpg'
import Capetown6 from '../images/capetown/capetown6.jpg'

import capetownCollection1 from '../images/capetown/capetown_collection1.jpg'
import capetownCollection2 from '../images/capetown/capetown_collection2.jpg'
import capetownCollection3 from '../images/capetown/capetown_collection3.jpg'
import capetownCollection4 from '../images/capetown/capetown_collection4.jpg'
import capetownCollection5 from '../images/capetown/capetown_collection5.jpg'
import capetownCollection6 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection7 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection8 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection9 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection10 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection11 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection12 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection13 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection14 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection15 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection16 from '../images/capetown/capetown_collection6.jpg'
import capetownCollection17 from '../images/capetown/capetown_collection6.jpg'

import contracts1 from '../images/contracts/contracts1.jpg'
import contracts2 from '../images/contracts/contracts2.jpg'
import contracts3 from '../images/contracts/contracts3.jpg'
import contracts4 from '../images/contracts/contracts4.jpg'

import contractsCollection1 from '../images/contracts//collection1.jpg'
import contractsCollection2 from '../images/contracts//collection2.jpg'
import contractsCollection3 from '../images/contracts//collection3.jpg'
import contractsCollection4 from '../images/contracts//collection4.jpg'
import contractsCollection5 from '../images/contracts//collection5.jpg'
import contractsCollection6 from '../images/contracts//collection6.jpg'
import contractsCollection7 from '../images/contracts//collection7.jpg'
import contractsCollection8 from '../images/contracts//collection8.jpg'
import contractsCollection9 from '../images/contracts//collection9.jpg'
import contractsCollection10 from '../images/contracts//collection10.jpg'
import contractsCollection11 from '../images/contracts//collection11.jpg'
import contractsCollection12 from '../images/contracts//collection12.jpg'
import contractsCollection13 from '../images/contracts//collection13.jpg'
import contractsCollection14 from '../images/contracts//collection14.jpg'
import contractsCollection15 from '../images/contracts//collection15.jpg'
import contractsCollection16 from '../images/contracts//collection16.jpg'
import contractsCollection17 from '../images/contracts//collection17.jpg'
import contractsCollection18 from '../images/contracts//collection18.jpg'
import contractsCollection19 from '../images/contracts//collection19.jpg'
import contractsCollection20 from '../images/contracts//collection20.jpg'
import contractsCollection21 from '../images/contracts//collection21.jpg'
import contractsCollection22 from '../images/contracts//collection22.jpg'


import drapperybook1051 from '../images/drappery/book-no-105/thumbnail1.jpg'
import drapperybook1052 from '../images/drappery/book-no-105/thumbnail2.jpg'
import drapperybook1053 from '../images/drappery/book-no-105/thumbnail3.jpg'
import drapperybook1054 from '../images/drappery/book-no-105/thumbnail4.jpg'
import drapperybook1055 from '../images/drappery/book-no-105/thumbnail5.jpg'
import drapperybook1056 from '../images/drappery/book-no-105/thumbnail6.jpg'

import drapperybook131 from '../images/drappery/book-no113/thumbnail1.jpg'
import drapperybook132 from '../images/drappery/book-no113/thumbnail2.jpg'
import drapperybook133 from '../images/drappery/book-no113/thumbnail3.jpg'
import drapperybook134 from '../images/drappery/book-no113/thumbnail4.jpg'

import drapperybook3021 from '../images/drappery/booNo302/thumbnail1.jpg'
import drapperybook3022 from '../images/drappery/booNo302/thumbnail2.jpg'
import drapperybook3023 from '../images/drappery/booNo302/thumbnail3.jpg'
import drapperybook3024 from '../images/drappery/booNo302/thumbnail4.jpg'

import drapperybook3061 from '../images/drappery/bookNo306/thumbnail1.jpg'
import drapperybook3062 from '../images/drappery/bookNo306/thumbnail2.jpg'
import drapperybook3063 from '../images/drappery/bookNo306/thumbnail3.jpg'
import drapperybook3064 from '../images/drappery/bookNo306/thumbnail4.jpg'

import drapperybook2041 from '../images/drappery/bookNo204/thumbnail1.jpg'
import drapperybook2042 from '../images/drappery/bookNo204/thumbnail2.jpg'
import drapperybook2043 from '../images/drappery/bookNo204/thumbnail3.jpg'
import drapperybook2044 from '../images/drappery/bookNo204/thumbnail4.jpg'

import upholsteryElegance1 from '../images/upholstery/elegance/thumbnail1.jpg'
import upholsteryElegance2 from '../images/upholstery/elegance/thumbnail2.jpg'
import upholsteryElegance3 from '../images/upholstery/elegance/thumbnail3.jpg'

import upholsteryAustria1 from '../images/upholstery/austria/thumbnail1.jpg'
import upholsteryAustria2 from '../images/upholstery/austria/thumbnail2.jpg'
import upholsteryAustria3 from '../images/upholstery/austria/thumbnail3.jpg'
import upholsteryAustria4 from '../images/upholstery/austria/thumbnail4.jpg'
import upholsteryAustria5 from '../images/upholstery/austria/thumbnail5.jpg'
import upholsteryAustria6 from '../images/upholstery/austria/thumbnail6.jpg'
import upholsteryAustria7 from '../images/upholstery/austria/thumbnail7.jpg'

import upholsteryCostarica1 from '../images/upholstery/costarica/thumbnail1.jpg'
import upholsteryCostarica2 from '../images/upholstery/costarica/thumbnail2.jpg'
import upholsteryCostarica3 from '../images/upholstery/costarica/thumbnail3.jpg'
import upholsteryCostarica4 from '../images/upholstery/costarica/thumbnail4.jpg'
import upholsteryCostarica5 from '../images/upholstery/costarica/thumbnail5.jpg'
import upholsteryCostarica6 from '../images/upholstery/costarica/thumbnail6.jpg'

import upholsteryCuba1 from '../images/upholstery/cuba/thumbnail1.jpg'
import upholsteryCuba2 from '../images/upholstery/cuba/thumbnail2.jpg'
import upholsteryCuba3 from '../images/upholstery/cuba/thumbnail3.jpg'
import upholsteryCuba4 from '../images/upholstery/cuba/thumbnail4.jpg'
import upholsteryCuba5 from '../images/upholstery/cuba/thumbnail5.jpg'
import upholsteryCuba6 from '../images/upholstery/cuba/thumbnail6.jpg'

import upholsteryGabon1 from '../images/upholstery/gabon/thumbnail1.jpg'
import upholsteryGabon2 from '../images/upholstery/gabon/thumbnail2.jpg'
import upholsteryGabon3 from '../images/upholstery/gabon/thumbnail3.jpg'
import upholsteryGabon4 from '../images/upholstery/gabon/thumbnail4.jpg'
import upholsteryGabon5 from '../images/upholstery/gabon/thumbnail5.jpg'
import upholsteryGabon6 from '../images/upholstery/gabon/thumbnail6.jpg'

const productDetails = [

    {
        "product_name": "Austria",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 430.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "No",
        "finish": "Matt",
        "has_details": true,
        "thumnail": [upholsteryAustria1, upholsteryAustria2, upholsteryAustria3, upholsteryAustria4, upholsteryAustria5, upholsteryAustria6, upholsteryAustria7],

    },
    {
        "product_name": "Capetown",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li> Weight (grm/mtr): 550.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "collection_list": [
            { "sku": "4601", "image": capetownCollection1 },
            { "sku": "4602", "image": capetownCollection2 },
            { "sku": "4603", "image": capetownCollection3 },
            { "sku": "4604", "image": capetownCollection4 },
            { "sku": "4605", "image": capetownCollection5 },
            { "sku": "4606", "image": capetownCollection6 },
            { "sku": "4607", "image": capetownCollection7 },
            { "sku": "4608", "image": capetownCollection8 },
            { "sku": "4609", "image": capetownCollection9 },
            { "sku": "4610", "image": capetownCollection10 },
            { "sku": "4611", "image": capetownCollection11 },
            { "sku": "4612", "image": capetownCollection12 },
            { "sku": "4613", "image": capetownCollection13 },
            { "sku": "4614", "image": capetownCollection14 },
            { "sku": "4615", "image": capetownCollection15 },
            { "sku": "4616", "image": capetownCollection16 },
            { "sku": "4616", "image": capetownCollection17 },

        ],
        "thumnail": [Capetown1, Capetown2, Capetown3, Capetown4, Capetown5, Capetown6],
        "has_details": true
    },
    {
        "product_name": "Chile",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 560.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "collection_list": [
            // { "sku": "01", "image": chileCollection01 },
            // { "sku": "02", "image": chileCollection02 },
            // { "sku": "03", "image": chileCollection03 },
            // { "sku": "04", "image": chileCollection04 },
            // { "sku": "05", "image": chileCollection05 },
            // { "sku": "06", "image": chileCollection06 },
            // { "sku": "07", "image": chileCollection07 },
            // { "sku": "08", "image": chileCollection08 },
            // { "sku": "09", "image": chileCollection09 },
            // { "sku": "10", "image": chileCollection10 },
            // { "sku": "11", "image": chileCollection11 },
            // { "sku": "12", "image": chileCollection12 },
            // { "sku": "13", "image": chileCollection13 },
            // { "sku": "14", "image": chileCollection14 },
            // { "sku": "15", "image": chileCollection15 },
            // { "sku": "16", "image": chileCollection16 },

        ],
        "thumnail": [Chile1, Chile2, Chile3, Chile4, Chile5, Chile6],
        "has_details": true
    },
    {
        "product_name": "Costarica",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 560.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Foil",
        "has_details": true,
        "thumnail": [upholsteryCostarica1, upholsteryCostarica2, upholsteryCostarica3, upholsteryCostarica4, upholsteryCostarica5, upholsteryCostarica6],
    },
    {
        "product_name": "Cuba",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 490.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li> Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 100,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "No",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Water Repellent, Matt Suede",
        "has_details": true,
        "thumnail": [upholsteryCuba1, upholsteryCuba2, upholsteryCuba3, upholsteryCuba4, upholsteryCuba5, upholsteryCuba6],
    },
    {
        "product_name": "Gabon",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 560.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 75,000 DOUBLE RUBS</li>
            <li>Backed: Yes</li>
            <li>Finish: Matt</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "has_details": true,
        "thumnail": [upholsteryGabon1, upholsteryGabon2, upholsteryGabon3, upholsteryGabon4, upholsteryGabon5, upholsteryGabon6],
    },
    {
        "product_name": "Georgia",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 560.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 40,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Foil",
        "has_details": true
    },

    {
        "product_name": "Haiti",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 600.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 75,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "has_details": true
    },

    {
        "product_name": "Iceland",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 480.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "No",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "has_details": true
    },
    {
        "product_name": "Malta",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 660.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 75,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "No",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Embossed",
        "has_details": true
    },

    {
        "product_name": "New York",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 560.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "has_details": true
    },
    {
        "product_name": "Norway",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 540.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 75,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "has_details": true
    },
    {
        "product_name": "Prague",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 600.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 75,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Matt",
        "has_details": true
    },

    {
        "product_name": "Russia",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li>Content: 100% POLYESTER</li>
            <li>Weight (grm/mtr): 550.0</li>
            <li>Width of Material (cm): 140.0</li>
            <li>Vert. Repeat (cm): 0.00</li>
            <li>Horiz. Repeat (cm): 0.00</li>
            <li>Design Direction: Up the Roll</li>
            <li>Country of Origin: India</li>
            <li>Flammability Rating: NFPA 260 on request</li>
            <li>Abrasion: 50,000 DOUBLE RUBS</li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "No",
        "end_use": "",
        "care_instructions": "",
        "backed": "Yes",
        "finish": "Water-Based Anti-Graffiti",
        "has_details": true
    },
    {
        "product_name": "Contracts",
        "product_category": "contracts",
        "product_description": `
            <ul>
            <li>Width : 320 cms</li>
            <li>Weight : 105 gsm</li>
            <li>Composition : 100% POLYESTER </li>
            <li>Washing Code :</li>
            </ul>`,
        "collection": "BOOK NO-302",
        "note": "Suitable for Curtain and Drapery",
        "end_use": "",
        "care_instructions": "",
        "collection_list": [
            { "sku": "01", "image": contractsCollection1 },
            { "sku": "02", "image": contractsCollection2 },
            { "sku": "03", "image": contractsCollection3 },
            { "sku": "04", "image": contractsCollection4 },
            { "sku": "05", "image": contractsCollection5 },
            { "sku": "06", "image": contractsCollection6 },
            { "sku": "07", "image": contractsCollection7 },
            { "sku": "08", "image": contractsCollection8 },
            { "sku": "09", "image": contractsCollection9 },
            { "sku": "10", "image": contractsCollection10 },
            { "sku": "11", "image": contractsCollection11 },
            { "sku": "12", "image": contractsCollection12 },
            { "sku": "13", "image": contractsCollection13 },
            { "sku": "14", "image": contractsCollection14 },
            { "sku": "15", "image": contractsCollection15 },
            { "sku": "16", "image": contractsCollection16 },
            { "sku": "17", "image": contractsCollection17 },
            { "sku": "18", "image": contractsCollection18 },
            { "sku": "19", "image": contractsCollection19 },
            { "sku": "20", "image": contractsCollection20 },
            { "sku": "21", "image": contractsCollection21 },
            { "sku": "22", "image": contractsCollection22 },

        ],
        "thumnail": [contracts1, contracts2, contracts3, contracts4],
        "has_details": false

    },

    {
        "product_name": "Linen Sheers Vol: 1",
        "product_category": "drappery",
        "product_description": `
            <ul>
            <li>Width : 320 cm with lead-band</li>
            <li>Weight : 110 GSM</li>
            <li>Composition : %100 polyester </li>
            <li>Washing Code :</li>
            <li>Usage : Curtain / Drapery</li>
            </ul>`,
        "collection": "BOOK NO-102",
        "note": "Treatments available on Request",
        "end_use": "",
        "care_instructions": "",
        "collection_list": [
            { "sku": "01", "image": contractsCollection1 },
            { "sku": "02", "image": contractsCollection2 },
            { "sku": "03", "image": contractsCollection3 },
            { "sku": "04", "image": contractsCollection4 },
            { "sku": "05", "image": contractsCollection5 },
            { "sku": "06", "image": contractsCollection6 },
            { "sku": "07", "image": contractsCollection7 },
            { "sku": "08", "image": contractsCollection8 },
            { "sku": "09", "image": contractsCollection9 },
            { "sku": "10", "image": contractsCollection10 },
            { "sku": "11", "image": contractsCollection11 },
            { "sku": "12", "image": contractsCollection12 },
            { "sku": "13", "image": contractsCollection13 },
            { "sku": "14", "image": contractsCollection14 },
            { "sku": "15", "image": contractsCollection15 },
            { "sku": "16", "image": contractsCollection16 },
            { "sku": "17", "image": contractsCollection17 },
            { "sku": "18", "image": contractsCollection18 },
            { "sku": "19", "image": contractsCollection19 },
            { "sku": "20", "image": contractsCollection20 },
            { "sku": "21", "image": contractsCollection21 },
            { "sku": "22", "image": contractsCollection22 },

        ],
        "thumnail": [contracts1, contracts2, contracts3, contracts4],
        "has_details": false
    },

    {
        "product_name": "BOOK NO - 204",
        "product_category": "drappery",
        "product_description": `
            <ul>
            <li>Text Here</li>
            </ul>`,
        "collection": "BOOK NO - 204",
        "note": "",
        "end_use": "",
        "care_instructions": "",
        "has_details": false,
        "thumnail": [drapperybook2041, drapperybook2042, drapperybook2043, drapperybook2044],
    },


    {
        "product_name": "BOOK NO-105",
        "product_category": "drappery",
        "product_description": `
            <ul>
            <li>Text Here</li>
            </ul>`,
        "collection": "BOOK NO-105",
        "note": "",
        "end_use": "",
        "care_instructions": "",
        "has_details": false,
        "thumnail": [drapperybook1051, drapperybook1052, drapperybook1053, drapperybook1054, drapperybook1055, drapperybook1056],
    },

    {
        "product_name": "BOOK NO-113",
        "product_category": "drappery",
        "product_description": `
            <ul>
            <li>Text Here</li>
            </ul>`,
        "collection": "BOOK NO-113",
        "note": "",
        "end_use": "",
        "care_instructions": "",
        "has_details": false,
        "thumnail": [drapperybook131, drapperybook132, drapperybook133, drapperybook134],
    },
    {
        "product_name": "BOOK NO-302",
        "product_category": "drappery",
        "product_description": `
            <ul>
            <li>Text Here</li>
            </ul>`,
        "collection": "BOOK NO-302",
        "note": "",
        "end_use": "",
        "care_instructions": "",
        "has_details": false,
        "thumnail": [drapperybook3021, drapperybook3022, drapperybook3023, drapperybook3024],
    },
    {
        "product_name": "BOOK NO-306",
        "product_category": "drappery",
        "product_description": `
            <ul>
            <li>Text Here</li>
            </ul>`,
        "collection": "BOOK NO-306",
        "note": "",
        "end_use": "",
        "care_instructions": "",
        "has_details": false,
        "thumnail": [drapperybook3061, drapperybook3062, drapperybook3063, drapperybook3064],
    },

    {
        "product_name": "Elegance",
        "product_category": "upholstery",
        "product_description": `
            <ul>
            <li></li>
            </ul>`,
        "product_type": "Velvet",
        "collection": "Upholstery Collection Vol:1",
        "design_type": "Textures/Solids",
        "print": "Yes",
        "end_use": "",
        "care_instructions": "",
        "backed": "No",
        "finish": "Matt",
        "has_details": true,
        "thumnail": [upholsteryElegance1, upholsteryElegance2, upholsteryElegance3],

    },
]



export default productDetails