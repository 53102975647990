import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ProductGrid from './ProductGrid'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import productDetails from "./ProductDetails";
import { Button, NoDataFound, SideDrawer } from "../Library/Module";
import { getCatlog } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";
import Loader from "../Library/Loader/Loader";

interface HomeProps {
    link: string;
}

const Category = ({ link }: HomeProps): JSX.Element => {
    const { mainCategory, breadcrumb, updateBreadcrumb, updateCatlogData, catlogData } = useContext(ThemeContext)
    const { category, product_title } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [selectedData, setSelectedData] = useState<any>('')
    const [loading, setLoading] = useState<any>(true)

    useEffect(() => {
        const data = productDetails?.filter((item) => {
            return item?.product_category === category
        })
        setData(data)
    }, [category])

    const selectedProduct = useCallback((data:any) => {
        console.log("selectedProduct data", data)
        setSelectedData(data)
        setOpen(true)
    }, [])


    const catagoryData = useMemo(() => {
        if (catlogData?.length !== 0) {
            return catlogData?.filter((item: any) => {
                return item?.category === category
            })
        }
    }, [category, catlogData])



    useEffect(() => {
        const data = [
            { "name": "Home", "link": "/", "icon": "" },
            { "name": category === "drappery" ? 'Drapery' : category, "link": `/category/${category}`, "icon": "" }
        ]
        updateBreadcrumb(data)
    }, [category, updateBreadcrumb])

    return (
        <>
            <div className={`banner banner2 ${category}`}>
                <div className="banner_text">
                    <h3 className="changeCase">{category === "drappery" ? 'Drapery' : category}</h3>
                </div>
            </div>
            <div className="">
                {breadcrumb}
            </div>
            <div className="">
                <div className="container pt-4 pb-4">
                    <div className="row">
                        {catagoryData?.length === 0 ? <NoDataFound></NoDataFound> :
                            catagoryData?.map((item: any) => {
                                return <>
                                    <ProductGrid
                                        isCategory={true}
                                        rowClass={'col-md-6 col-sm-6 mb-4'}
                                        hideCollection selectedProduct={selectedProduct} data={item}></ProductGrid>
                                </>
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Category;
