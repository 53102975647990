import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import './css/bootstrap.css';
import './css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.css';
import { ToastContainer } from 'react-toastify';
import { setUpAxios } from './servies/services';
import HomePage from './container/Home';
import { ThemeProvider } from './Context/Theme/Context';
import PageNotFound from './container/PageNotFound';
import Wrapper from './container/Wrapper';
import Category from './container/Category';
import ScrollToTop from './components/ScrollToTop';
import ProductDetails from './container/ProductDetailsNew';
import ContactUs from './container/ContactUs';
import AboutUs from './container/AboutUs';
import FindDealer from './container/FindDealer';
import MakePayment from './container/MakePayment';
import ThankYou from './container/ThankYou';

function App() {
  setUpAxios();
  console.log("window.location.pathname setUpAxios", window.location.pathname)
  console.log("window.location.search setUpAxios", window.location.search)
  return (
    <>
      {/* basename={'/tlegalintranet-admin'} */}
      <BrowserRouter >
        <ThemeProvider>
          <ScrollToTop path={window.location.pathname} />
          <Wrapper>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/category/:category" element={<Category />} />
              <Route path="/category/:category/:product_title" element={<ProductDetails />} />

              <Route path="/find-dealer" element={<FindDealer />} />
              <Route path="/make-a-payment" element={<MakePayment />} />
              <Route path="/payment-details/success" element={<ThankYou />} />
              <Route path="/payment-details/cancel" element={<ThankYou />} />
              {/* session_id=cs_test_a1GLEdaw3Ej6Hi4FSPeFMbZDlydDcjSVLVjUXd8IyAMhpqjUq26x708JxI */}

              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Wrapper>
          <ToastContainer></ToastContainer>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App