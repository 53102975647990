import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import Collection from '../images/new_collection.png'
import Collection2 from '../images/new_collection2.png'
import Collection3 from '../images/new_collection3.png'
import catDrappery from '../images/cat_drappery.png'
import catDrappery2 from '../images/cat_drappery.png'



import Wrapper from "./Wrapper";
import { NavLink } from "react-router-dom";
import { Button, Input } from "../Library/Module";

interface HomeProps {
    link: string;
}

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};


const ContactUs = ({ link }: HomeProps): JSX.Element => {
    const { orgUser } = useContext(ThemeContext)
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = () => {
        alert("Form has been submitted successfully")
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    return (
        <>
            <div className="banner banner2">
                <div className="banner_text">
                    <h3 className="changeCase">About Us</h3>
                </div>
            </div>

            <div className="container pt-4 pb-4">
                {/* <div className="row justify-content-center text-center">
                    <div className="col-md-4 col-sm-12 pb-5">
                    
                    </div>
                </div> */}

                <div className="row justify-content-center pb-3 pt-3">
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h3>VISION OF THE COMPANY</h3>
                                <p>D3 is a premier supplier of furnishing fabrics in the Middle East and North Africa region. Our mission is to deliver exceptional customer service and unmatched product quality, with a goal of becoming the world's leading furnishing fabrics company. We offer a wide range of both classic and modern designs, as well as versatile solid colors, and our products are known for their stylish diversity and timeless elegance, all complemented by a sophisticated color palette.</p>
                                <p><span className="material-symbols-outlined"> mail </span> info@dynamicfabrics.net </p>
                                <p><span className="material-symbols-outlined"> call </span> +971 4 8785797</p>
                                {/* <p><span className="material-symbols-outlined"> pin_drop </span> +971 4 8785797</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="section text-center">
                            <h3>About Us</h3>
                            <p className="text-center">Established in 2019 as Dynamic Fabrics Fzco, D3, or Dynamic Design Decor, operates from its headquarters in Dubai, United Arab Emirates, specializing in furnishing fabrics. Our focus is to bring comfort and a sense of home to our customers through a combination of fashion-forward trends and simplicity.</p>
                        </div>
                    </div>
                </div>

                <div className="row pb-3 pt-3">
                    <div className="col-md-6 col-sm-12">
                        <div className="section">
                            <h3>Our Legacy</h3>
                            <p>Our company boasts 18 years of manufacturing expertise, specializing in creating custom-made, value-engineered fabrics. With our team's extensive knowledge and experience, we are dedicated to providing top-quality products that meet the unique needs of our customers.</p>
                            <p>We strive to continuously improve our manufacturing processes and technology to ensure that we remain at the forefront of the industry. Our commitment to excellence and customer satisfaction has helped us establish a solid reputation in the market, and we look forward to continuing to exceed the expectations of our clients for many years to come.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h3>OUR AIM</h3>
                                <p>With a passion for staying ahead of fashion trends, D3 is committed to creating innovative products that challenge individuals to transform their interior spaces. We strive to be at the forefront of design and bring inspiration to our customers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contactMap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.9780144051515!2d55.41996880000001!3d25.1702202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f616d8669fb49%3A0xd0828ceca868c86!2sD3-Dynamic%20Fabrics%20FZCO!5e0!3m2!1sen!2sin!4v1694449200628!5m2!1sen!2sin" width="100%" height="450" loading="lazy" title="contactAddress"></iframe>
            </div>
        </>
    );
};
export default ContactUs;
